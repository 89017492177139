import { cva, type VariantProps } from 'class-variance-authority'
import { motion } from 'framer-motion'
import React from 'react'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'

const inputVariants = cva(
	'peer z-20 h-fit w-full appearance-none rounded-lg border border-beige-90 border-input bg-white py-4 pr-6 ring-offset-background placeholder:text-muted-foreground placeholder:opacity-20 focus:outline focus:outline-1 focus:outline-black-90 disabled:cursor-not-allowed disabled:opacity-50',
	{
		variants: {
			variant: {
				default: 'pl-6',
				icon: 'pl-12',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {
	error?:
		| {
				message?: string
		  }
		| undefined
	isValid?: boolean
	icon?: string // Icon name, optional
}

export const InputField = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type,
			placeholder,
			isValid,
			error,
			icon,
			variant = 'default',
			...props
		},
		ref,
	) => {
		const inputId = props.id || props.name

		return (
			<div className={cn('space-y-2', className)}>
				<div className="relative">
					{/* Conditionally render the icon inside the input field */}
					{variant === 'icon' && icon && (
						<Icon
							name={icon} // Dynamically use the passed icon name
							className="absolute left-4 top-1/2 h-6 w-6 -translate-y-1/2 transform text-black"
						/>
					)}
					<input
						id={inputId}
						type={type}
						placeholder=" "
						className={cn(
							'peer z-20 h-fit w-full appearance-none rounded-lg border border-beige-90 border-input bg-white py-4 pr-6 ring-offset-background placeholder:text-muted-foreground placeholder:opacity-20 focus:outline focus:outline-1 focus:outline-black-90 disabled:cursor-not-allowed disabled:opacity-50',
							variant === 'icon' ? 'pl-12' : 'pl-6',
							error && 'border-red text-red focus:outline-red', // Apply error styles directly
							className,
						)}
						ref={ref}
						{...props}
					/>
					<label
						htmlFor={props.name}
						className={cn(
							'absolute top-2 z-30 origin-[0] -translate-y-2 scale-75 select-none bg-transparent text-sm text-gray-500 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-slate-700',
							variant === 'icon' ? 'left-12' : 'left-6',
							error && 'text-red', // Apply error styles directly
						)}
					>
						{placeholder}
					</label>
					<motion.div
						className="text-mint-green-60 opacity-0"
						animate={{ opacity: isValid ? 1 : 0 }}
					>
						<Icon name="check" size="lg" className="absolute right-6 top-4" />
					</motion.div>
				</div>
				<PlentyBody
					className={cn(
						'bg-transparent text-red',
						error?.message ? 'flex' : 'hidden',
					)}
					size="sm"
				>
					{error?.message?.toString()}
				</PlentyBody>
			</div>
		)
	},
)
InputField.displayName = 'Input'
